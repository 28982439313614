export default function mapHrefToAppUrl ({ path, keepQueryString = true, sourceHref = window.location.href } = {}) {
	const sourceUrl = new URL(sourceHref);
	const targetUrl = new URL(
		sourceUrl.origin
			// Handle local env
			.replace(/:8041$/, ':4000')
			// Handle local env with proxy (live.bk-username.<your-domain>.example)
			.replace(/^(https?:\/\/)live\./, '$1refront.')
			// Handle exposed local with proxy (live-<name>.lan.compute.beekast.com)
			.replace(/live-(.*)\.lan\.compute/, 'refront-$1.lan.compute')
			.replace(/.live$/, '.com')
			// Handle "my" production subdomain
			.replace(/^(https?:\/\/)(www\.)?beekast/, '$1my.beekast')
			// Handle feature env
			.replace(/^(https?:\/\/)(.*)-live.compute.beekast.com/, '$1$2.compute.beekast.com')
	);

	targetUrl.pathname = path || sourceUrl.pathname;

	if (keepQueryString) {
		sourceUrl.searchParams.forEach((value, key) => targetUrl.searchParams.set(key, value));
	} else {
		// keep embed query if present
		const embedParam = sourceUrl.searchParams.get('embed');
		if (embedParam) {
			targetUrl.searchParams.set('embed', embedParam);
		}
	}

	targetUrl.searchParams.set('referrer', sourceUrl.origin);

	if (document?.getElementById('lang')?.value) {
		targetUrl.searchParams.set('lang', document.getElementById('lang').value);
	}

	return targetUrl.toString();
}
