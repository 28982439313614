/* eslint-disable no-console */
import i18nFR from './i18n/fr';
import i18nEN from './i18n/en';
import i18nPT from './i18n/pt';
import i18nIT from './i18n/it';
import i18nDE from './i18n/de';
import i18nNL from './i18n/nl';
import i18nES from './i18n/es';
import i18next from 'i18next';
import mapHrefToAppUrl from './utils/map-href-to-app-url';

// eslint-disable-next-line
import '../styles/main.scss';

const { FRONT_API_URL, LIVE_HOSTNAME } = window; // just a linter things
const i18n = i18next;

// we need userlanguage before redirecting to app route
initI18n();

// Redirect all paths to web application
(function redirect () {
	const path = window.location.pathname;
	if (path && path !== '/' && path !== '/index.html') {
		location.href = mapHrefToAppUrl();
	}
}());

// That's the only way to use our api
const authenticating = fetch(`${FRONT_API_URL}auth/me`, {
	mode: 'cors',
	credentials: 'include'
});

document.addEventListener('DOMContentLoaded', (/* event */) => {
	checkDomain();

	// Set custom env customization
	/* eslint-disable no-restricted-properties */
	if (window.env) {
		customEnv();
	}

	// Listen lang change
	const langSelect = document.getElementById('lang');
	langSelect.addEventListener('change', changeLanguage);

	// Listen submit
	const joinForm = document.getElementById('joinForm');
	joinForm.addEventListener('submit', joinSubmit);

	// Listen focus
	const joinInput = document.getElementById('joinCode');
	joinInput.addEventListener('focus', joinInputFocus);

	// Trigger focus
	joinInputFocus();

	// Listen keyup
	joinInput.addEventListener('keyup', joinInputKeyup);

	checkRedirectError();
});

// Submit
function joinSubmit (event) {
	event.preventDefault();
	const joinCode = document.getElementById('joinCode');
	if (joinCode && joinCode.value !== '') {
		joinWithPassword(joinCode.value);
	} else {
		joinError();
	}
}

// Focus
function joinInputFocus () {
	joinErrorReset();
	const joinInput = document.getElementById('joinCode');
	joinInput.focus();
}

// Error
function joinError () {
	const joinError = document.getElementById('form-error');
	joinError.classList.add('live__form-error--show');

	const joinForm = document.getElementById('joinForm');
	joinForm.classList.add('live__form--has-error');
}

function showErrorSuspicious () {
	document.getElementById('redirect-error-suspicious_activity').classList.add('live__form-error--show');
	document.getElementById('joinForm').classList.add('live__form--has-error');
}

function showErrorKastNotFound () {
	document.getElementById('redirect-error-not_found').classList.add('live__form-error--show');
	document.getElementById('joinForm').classList.add('live__form--has-error');
}

function loading (isLoading) {
	const $joinForm = document.getElementById('joinForm');
	const loadingClassName = 'live__form--loading';
	if (isLoading) {
		$joinForm.classList.add(loadingClassName);
	} else {
		$joinForm.classList.remove(loadingClassName);
	}
}

// Error reset
function joinErrorReset () {
	const joinError = document.getElementById('form-error');
	joinError.classList.remove('live__form-error--show');

	const joinForm = document.getElementById('joinForm');
	joinForm.classList.remove('live__form--has-error');
}

// Keyup
function joinInputKeyup () {
	const joinInput = document.getElementById('joinCode');
	joinInput.value = joinInput.value.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '');

	if (joinInput.value === '') {
		checkRedirectErrorReset();
	}
}

async function joinWithPassword (password) {
	loading(true);
	const { kast, error } = await authenticating
		.then(() => fetch(`${FRONT_API_URL}graph/`, {
			method: 'POST',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				query: `
					mutation resolveAccessChallenge($password: String!) {
						kast {
							resolveAccessChallenge(password: $password) {
								accessChallenge {
									codeReward
								}
							}
						}
					}
				`,
				variables: {
					password
				}
			})
		}))
		.then((res) => res.json())
		.then((result) => ({
			kast: result.data.kast.resolveAccessChallenge,
			error: result.errors && result.errors[0]
		}))
		.finally(() => loading(false));

	if (error) {
		if (error.message === 'RATE_LIMIT_REACHED') {
			showErrorSuspicious();
		} else {
			showErrorKastNotFound();
		}
		// eslint-disable-next-line no-negated-condition
	} else if (!kast) {
		showErrorKastNotFound();
	} else {
		document.location.href = mapHrefToAppUrl({
			path: kast.accessChallenge.codeReward,
			keepQueryString: false
		});
	}
}

/**
 * Check domain validity
 * Makes sense for production use. We need to use www.beekast.live instead of beekast.live
 * This is due to CSRF and CORS concerns
 */
function checkDomain () {
	// it seems that LIVE_HOSTNAME always returns www.beekast.live, so for custom url (console conf like for Apec)
	// we should compare the custom env with the window.location.host
	// let says custom env is like apec.beekast.com, we need to compare with apec.beekast.live

	const customEnv = window.env.customEnv;
	const liveURl = customEnv ? customEnv.replace(/\.com\b/g, '.live') : LIVE_HOSTNAME;

	if (window.location.host !== liveURl) {
		const redirectURL = new URL(window.location.search, `https://${LIVE_HOSTNAME}`);
		console.log(`Wrong live hostname, redirecting from ${window.location.href} to ${redirectURL.href}`);
		window.location.replace(redirectURL.href);
	}
}

// Translations
function initI18n () {
	const lang = getLang();

	i18n.init(
		{
			lng: lang,
			fallbackLng: 'en',
			resources: {
				en: { translation: i18nEN },
				fr: { translation: i18nFR },
				es: { translation: i18nES },
				de: { translation: i18nDE },
				it: { translation: i18nIT },
				nl: { translation: i18nNL },
				pt: { translation: i18nPT }
			}
		},
		() => {
			// Set lang select active item
			document.getElementById('lang').value = i18next.languages.find((lang) => ['en', 'fr', 'es', 'de', 'it', 'nl', 'pt'].includes(lang));

			updateTranslation();
		}
	);
}

// User lang
function getLang () {
	const urlLang = getParameterByName('locale') || getParameterByName('lang');
	if (urlLang === 'en' || urlLang === 'fr') {
		return urlLang;
	}
	const navigatorLang = navigator.userLanguage || navigator.language;
	const shortLang = navigatorLang.toLowerCase().split('-')[0]; // will transform 'fr-FR' to 'fr'
	return shortLang;
}

function updateTranslation () {
	// Set title
	document.title = i18n.t('TITLE') + ' - ' + i18n.t('TITLE_INFORMATION');

	// Get all nodes from data attribute
	const i18nNodes = document.querySelectorAll('[data-i18n]');
	// using for instead of forEach because IE : https://developer.mozilla.org/fr/docs/Web/API/NodeList
	for (let i = 0; i < i18nNodes.length; i++) {
		const node = i18nNodes[i];
		node.innerHTML = i18n.t(node.dataset.i18n);
	}
	// Custom placeholder
	const i18nPlaceholderNode = document.querySelector('[data-i18n-placeholder]');
	i18nPlaceholderNode.placeholder = i18n.t(i18nPlaceholderNode.dataset.i18nPlaceholder);

	// Set lang attribute
	document.getElementsByTagName('html')[0].setAttribute('lang', i18n.language);
}
/* eslint-enable */

function changeLanguage (event) {
	i18n.changeLanguage(event.target.value);
	updateTranslation();
}

function checkRedirectError () {
	const error = getParameterByName('kast_error');
	if (error) {
		const redirectError = document.getElementById('redirect-error-' + error);
		if (redirectError) {
			redirectError.classList.add('live__form-error--show');

			const joinForm = document.getElementById('joinForm');
			joinForm.classList.add('live__form--has-error');

			// Fill input with kast code
			const code = getParameterByName('kast_code');
			const joinInput = document.getElementById('joinCode');
			joinInput.value = code;
		}
	}
}

function checkRedirectErrorReset () {
	// Reset not found error
	const redirectErrorNotFound = document.getElementById('redirect-error-not_found');
	redirectErrorNotFound.classList.remove('live__form-error--show');

	// Reset closed error
	const redirectErrorClosed = document.getElementById('redirect-error-closed');
	redirectErrorClosed.classList.remove('live__form-error--show');

	const joinForm = document.getElementById('joinForm');
	joinForm.classList.remove('live__form--has-error');
}

function getParameterByName (name, url) {
	if (!url) {
		url = window.location.href;
	}

	const newUrl = new URL(url);
	return newUrl.searchParams.get(name);
}

function customEnv () {
	/* eslint-disable no-restricted-properties */
	const customColor = window.env.customColor;
	const customLogo = window.env.customLogo;
	const customName = window.env.customName;

	if (customColor) {
		// Custom text color
		setCustomColor(customColor);
		// Custom background color
		setCustomBackgroundColor(customColor);
		// Custom fill color
		setCustomFillColor(customColor);
		// Custom border color
		setCustomBorderColor(customColor);
	}

	if (customLogo) {
		setCustomLogo(customLogo);
	}

	if (customName) {
		document.title = customName + ' - ' + i18n.t('TITLE_INFORMATION');
	}

	if (customColor || customLogo || customName) {
		document.body.classList.add('body--custom-env');
		setCustomHideElements();
	}
}

function setCustomColor (color) {
	const toCustomNodes = document.querySelectorAll('.custom-env__color');
	// using for instead of forEach because IE : https://developer.mozilla.org/fr/docs/Web/API/NodeList
	for (let i = 0; i < toCustomNodes.length; i++) {
		const node = toCustomNodes[i];
		node.style.color = color;
	}
}

function setCustomBackgroundColor (color) {
	const toCustomNodes = document.querySelectorAll('.custom-env__background-color');
	// using for instead of forEach because IE : https://developer.mozilla.org/fr/docs/Web/API/NodeList
	for (let i = 0; i < toCustomNodes.length; i++) {
		const node = toCustomNodes[i];
		node.style.backgroundColor = color;
	}
}

function setCustomFillColor (color) {
	const toCustomNodes = document.querySelectorAll('.custom-env__fill-color');
	// using for instead of forEach because IE : https://developer.mozilla.org/fr/docs/Web/API/NodeList
	for (let i = 0; i < toCustomNodes.length; i++) {
		const node = toCustomNodes[i];
		node.style.fill = color;
	}
}

function setCustomBorderColor (color) {
	const toCustomNodes = document.querySelectorAll('.custom-env__border-color');
	// using for instead of forEach because IE : https://developer.mozilla.org/fr/docs/Web/API/NodeList
	for (let i = 0; i < toCustomNodes.length; i++) {
		const node = toCustomNodes[i];
		node.style.borderColor = color;
	}
}

function setCustomLogo (logo) {
	const toCustomNode = document.querySelector('.custom-env__logo');
	toCustomNode.classList.add('custom-env__logo--customized');
	const toCustomImg = toCustomNode.querySelector('img');
	toCustomImg.src = logo;
}

function setCustomHideElements () {
	const toCustomNodes = document.querySelectorAll('.custom-env__hide');
	// using for instead of forEach because IE : https://developer.mozilla.org/fr/docs/Web/API/NodeList
	for (let i = 0; i < toCustomNodes.length; i++) {
		const node = toCustomNodes[i];
		node.classList.add('hide');
	}
}
